import { createGlobalStyle } from 'styled-components'
import './theme/tailwind.css'
// import './theme/responsive.scss'
import './theme/highcharts'

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
    font-size: 87.5%;
  }

  button {
    cursor: pointer;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0.5em 0 0.5em 0;
  }

  .ant-picker-panels {
  display: flex;
  justify-content: center;
  align-items: center;
}

  /* make highcharts resize itself on window resizes.
   * without this, extra scroll gets introduced when shrinking window size after expanding it
   */
  .highcharts-container,
  .highcharts-container svg {
     width: 100% !important;
  }

`

export default GlobalStyle
